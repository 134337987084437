import { DenormalizedField } from '../../containers/landing-page/selectors'
import { check, isEmptyOrUndefined } from '../../utils/field-validations'

export const validateAllFields = (
  fields: DenormalizedField[],
  data: Record<string, string>,
) =>
  fields.find(field => {
    if (!field.enabled) return false
    if (isEmptyOrUndefined(data[field.name])) return true

    const isAValidValue = !!field.settings.validations?.find(
      validation => !check(validation, data[field.name]),
    )

    return isAValidValue
  })
